import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Home from "./pages/home/Home";
import NavBar from "./layout/BottomNavigation";
import Profile from "./pages/Profile/Profile";
import Saved from "./pages/Saved/Saved";
import { useEffect, useRef, useState } from "react";
import {
  AnonymousLogin,
  CreateAnonymousUser,
  GetUser,
  GetUserProducts,
} from "./services";
import {
  setIsLoading,
  setProducts,
  setSavedProducts,
} from "./store/reducers/products.reducer";
import { setUser } from "./store/reducers/user.reducer";
import { store } from "./store";
import axios from "axios";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

function App() {
  const { fetchedProducts } = useSelector((state) => state.products);
  const { user } = useSelector((state) => state.user);
  const [lastUserPreferences, setLastUserPreferences] = useState([]);
  const [isRunOutOfProducts, setIsRunOutOfProducts] = useState(false);
  const localStorageUserId = localStorage.getItem("user_id");
  const userId = useRef();

  const fetchUserData = async () => {
    store.dispatch(setIsLoading(true));
    try {
      let user;

      if (localStorage.getItem("token") && localStorageUserId) {
        axios.defaults.headers.common["Token"] =
          "Authorization " + localStorage.getItem("token");
        user = await GetUser(localStorageUserId).then(({ data }) => data.data);
        userId.current = localStorageUserId;
      } else {
        user = await CreateAnonymousUser().then(({ data }) => data.data);
        const login = await AnonymousLogin(user.id).then(
          ({ data }) => data.data,
        );
        axios.defaults.headers.common["Token"] = "Authorization " + login.token;
        localStorage.setItem("token", login.token);
        localStorage.setItem("user_id", user.id);
        userId.current = user.id;
      }

      store.dispatch(setUser(user));
      setLastUserPreferences(user?.preferences?.types ?? []);

      fetchNewProducts(true);
    } catch (e) {
      console.log(e);
    }
    store.dispatch(setIsLoading(false));
  };

  const fetchNewProducts = async (shouldLoadSavedCloth = false) => {
    try {
      setLastUserPreferences(user?.preferences?.types ?? []);
      const newFetchedProducts = await GetUserProducts(userId.current).then(
        ({ data }) => data,
      );
      store.dispatch(setProducts(newFetchedProducts?.new_cloths ?? []));
      if (newFetchedProducts.new_cloths_total < 50) {
        setIsRunOutOfProducts(true);
      }
      if (shouldLoadSavedCloth) {
        store.dispatch(setSavedProducts(newFetchedProducts.saved_cloths));
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    if (
      ((fetchedProducts?.length < 10 && !isRunOutOfProducts) ||
        (user?.preferences?.types &&
          !isEqual(lastUserPreferences, user?.preferences?.types))) &&
      userId?.current
    ) {
      fetchNewProducts();
    }
  }, [fetchedProducts, user]);

  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route path={"/home"} element={<Home />} />
        <Route path={"/profile"} element={<Profile />} />
        <Route path={"/saved"} element={<Saved />} />
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
