import { store } from "../store";
import { setIsLoading } from "../store/reducers/products.reducer";
import axios from "axios";
import { AnonymousLogin, CreateAnonymousUser, GetUser } from "../services";
import { setUser } from "../store/reducers/user.reducer";

export const fetchUserData = async () => {
  store.dispatch(setIsLoading(true));
  try {
    let user;

    if (localStorage.getItem("token") && localStorage.getItem("user_id")) {
      axios.defaults.headers.common["Token"] =
        "Authorization " + localStorage.getItem("token");
      user = await GetUser(localStorage.getItem("user_id")).then(
        ({ data }) => data.data,
      );
    } else {
      user = await CreateAnonymousUser().then(({ data }) => data.data);
      const login = await AnonymousLogin(user.id).then(({ data }) => data.data);
      axios.defaults.headers.common["Token"] = "Authorization " + login.token;
      localStorage.setItem("token", login.token);
      localStorage.setItem("user_id", user.id);
    }

    store.dispatch(setUser(user));
  } catch (e) {
    console.log(e);
  }
  store.dispatch(setIsLoading(false));
};
